<template>
  <div v-if="isVisible" class="full-screen-dialog">
    <div class="dialog-content">
      <div class="dialog-header">
        <h2>{{ title }}</h2>
      </div>
      <div class="dialog-body">
        <!-- Отображаем сообщение, если оно передано -->
        <p v-if="message" v-html="message"></p>
        <!-- Компонент Fish -->
        <Fish v-if="fishName" :imageName="fishName" />
        <!-- Слот для дополнительного контента -->
        <slot></slot>
        <button @click="closeDialog">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<script>
import Fish from '@/scenes/shore/Fish.vue'; // Импортируем компонент Fish

export default {
  name: 'FullScreenDialog',
  components: {
    Fish
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: 'Диалоговое окно'
    },
    message: {
      type: String,
      default: '' // Сообщение по умолчанию пустое
    },
    fishName: {
      type: String,
      default: '' // Название рыбы по умолчанию
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
.full-screen-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background-color: #808026;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dialog-header h2 {
  margin: 0;
}

.dialog-body {
  /* Стили для тела диалога */
}
</style>