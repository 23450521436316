import Core from '@/scenes/shore/classes/Core'
import Rod from '@/scenes/shore/classes/Rod'
import Bait from '@/scenes/shore/classes/Bait'

import { createStore } from 'vuex'

const store = createStore({
    // Если вам нужно просто получить значение из состояния и не требуется никакой дополнительной логики,
    // использование состояния напрямую может быть проще и более понятным.
    state: {
        debugMode: false, // Для дебага - рисовать ли линии?
        baits: [], // Приманки
        rods: [], // Удочки
        selectedRod: null, // Выбранная приманка
        selectedBait: null, // Выбранная приманка
        isDialogVisible: false, // Отображается ли диалог
        dialogTitle: '', // Заголовок диалога
        dialogFishName: '', // Название рыбы
        dialogMessage: '', // Текст диалога
        user: { // Добавляем объект для хранения данных пользователя
            id: null,
            name: null,
        },
    },
    // Если вам нужно произвести какую-то обработку данных перед тем, как использовать их в компоненте, лучше использовать getters.
    // Например, если вы хотите отфильтровать массив, преобразовать его или агрегировать данные,
    // getters обеспечивают чистый и переиспользуемый способ выполнения этой логики.
    // Они также кешируют свои данные для увеличения производительности
    getters: {
        // selectedRod(state) {
        //     return state.rods.find(r => r.id === state.selectedRod)
        // },
    },
    // Это единственный способ синхронно изменять состояние (state) в Vuex.
    // Они всегда должны быть синхронными, поскольку Vuex отслеживает изменения состояния,
    // чтобы обновления были предсказуемыми и отслеживаемыми.
    // Ваша мутация может напрямую изменять состояние, например:
    mutations: {

        // Инициализируем данные
        INIT_STORE(state) {

            // Приманки
            state.baits = [ // Название | Кол-во
                new Bait('Червь', 5),
                new Bait('Опарыш', 5),
                new Bait('Хлеб', 5),
            ]

            // Удочки
            state.rods = [ // ID | Название
                new Rod('Удочка 1', 1),
                new Rod('Удочка 2', 2),
                new Rod('Удочка 3', 3),
            ]

            // Инициализируем ядро
            Core.initialize(store)
        },

        // Обновление данных пользователя
        SET_USER(state, user) {
            state.user.id = user.id;
            state.user.name = user.name;
        },

        SET_SELECTED_ROD(state, rod) {
            state.selectedRod = rod
        },
        SET_SELECTED_BAIT(state, bait) {
            state.selectedBait = bait
        },

        // Открыть диалог
        OPEN_DIALOG(state, payload) {
            state.isDialogVisible = true;
            state.dialogTitle = payload.title;
            state.dialogFishName = payload.fishName;
            state.dialogMessage = payload.message;
        },

        // Закрыть диалог
        CLOSE_DIALOG(state) {
            state.isDialogVisible = false;
            state.dialogTitle = '';
            state.dialogFishName = '';
            state.dialogMessage = '';
        },

        // START_REELING(state) {
        //     state.isReeling = true
        // },
        // STOP_REELING(state) {
        //     state.isReeling = false
        // },
        // UPDATE_ROD_POSITION(state, { rodId, newPosition }) {
        //     const rod = state.rods.find(r => r.id === rodId)
        //     if (rod) {
        //         rod.castLocation.y = newPosition
        //     }
        // },
        // STOP_ANIMATION(state, rodId) {
        //     const rod = state.rods.find(r => r.id === rodId)
        //     if (rod) {
        //         rod.isBiting = false
        //     }
        // },
    },
    // Действия используются для выполнения асинхронных операций или для любой другой логики перед изменением состояния.
    // В отличие от мутаций, действия не могут напрямую изменять состояние.
    // Вместо этого действия вызывают мутации через commit, как в примере:
    actions: {
        // Обновление данных пользователя
        setUser({ commit }, user) {
            commit('SET_USER', user);
        },

        setSelectedRod({ commit }, rodId) {
            commit('SET_SELECTED_ROD', rodId)
        },
        setSelectedBait({ commit }, bait) {
            commit('SET_SELECTED_BAIT', bait)
        },

        // Открыть диалог
        openDialog({ commit }, payload) {
            commit('OPEN_DIALOG', payload);
        },

        // Закрыть диалог
        closeDialog({ commit }) {
            commit('CLOSE_DIALOG');
        },
        // startReeling({ commit }, rodId) {
        //     commit('START_REELING')
        //     commit('SET_SELECTED_ROD', rodId)
        // },
        // stopReeling({ commit }) {
        //     commit('STOP_REELING')
        // },
        // reelIn({ commit, state }, { rodId, canvasHeight }) {
        //     const rod = state.rods.find(r => r.id === rodId)
        //     if (!rod) return
        //
        //     const targetY = canvasHeight
        //
        //     const animate = () => {
        //         if (rod.castLocation.y < targetY) {
        //             commit('UPDATE_ROD_POSITION', { rodId, newPosition: rod.castLocation.y + 1 })
        //             requestAnimationFrame(animate)
        //         } else {
        //             commit('STOP_ANIMATION', rodId)
        //         }
        //     }
        //
        //     requestAnimationFrame(animate)
        // },
    },
})


export default store