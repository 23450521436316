export default class WaterBody {
    constructor(name, fishes) {
        this.name = name; // Название водоема
        this.fishes = fishes; // Массив рыб с их параметрами для этого водоема
    }

    // Получение случайной рыбы с учетом шанса улова и времени
    getRandomFish(currentHour) {
        const availableFishes = this.fishes.filter(fish => {
            // Проверяем, активна ли рыба в текущее время
            return fish.activeTimes.some(time => {
                if (time.start <= time.end) {
                    // Обычный случай: время активности в пределах одного дня
                    return currentHour >= time.start && currentHour <= time.end;
                } else {
                    // Случай, когда время активности переходит через полночь
                    return currentHour >= time.start || currentHour <= time.end;
                }
            });
        });

        if (availableFishes.length === 0) return null;

        // Суммируем шансы улова для всех доступных рыб
        const totalChance = availableFishes.reduce((sum, fish) => sum + fish.catchChance, 0);
        let randomChance = Math.random() * totalChance;

        // Выбираем рыбу на основе шанса
        for (const fish of availableFishes) {
            if (randomChance < fish.catchChance) {
                return fish;
            }
            randomChance -= fish.catchChance;
        }

        return null;
    }
}