export default class Fish {
    constructor(name, minWeight, maxWeight, minSize, maxSize, pricePerKg, resistance, escapeChance) {
        this.name = name; // Название рыбы
        this.minWeight = minWeight; // Минимальный вес (кг)
        this.maxWeight = maxWeight; // Максимальный вес (кг)
        this.minSize = minSize; // Минимальный размер (см)
        this.maxSize = maxSize; // Максимальный размер (см)
        this.pricePerKg = pricePerKg; // Стоимость за 1 кг (руб)
        this.resistance = resistance; // Сопротивляемость вываживанию (0-100%)
        this.escapeChance = escapeChance; // Шанс срыва (0-100%)

        // Коэффициент для расчета веса на основе размера
        this.weightCoefficient = this.calculateWeightCoefficient();
    }

    // Рассчитываем коэффициент для формулы веса
    calculateWeightCoefficient() {
        // Используем средний размер и средний вес для расчета коэффициента
        const avgSize = (this.minSize + this.maxSize) / 2;
        const avgWeight = (this.minWeight + this.maxWeight) / 2;
        return avgWeight / Math.pow(avgSize, 3);
    }

    // Генерация случайного размера на основе sizePercentage
    getAdjustedSize(sizePercentage) {
        // Базовый размер (50% от диапазона)
        const baseSize = this.minSize + (this.maxSize - this.minSize) * (sizePercentage / 100);

        // Отклонение: -30% до +15% от базового размера
        const deviation = (Math.random() * 0.45 - 0.30) * baseSize; // -30% до +15%
        let adjustedSize = baseSize + deviation;

        // Редкий шанс (5%) поймать рыбу с размером до 100%
        let hasTrophyChance = false;
        if (Math.random() < 0.05) {
            adjustedSize = this.minSize + (this.maxSize - this.minSize) * (sizePercentage / 100) * Math.random();
            hasTrophyChance = true; // Устанавливаем флаг, что есть шанс на трофей
        }

        // Ограничиваем размер минимальным и максимальным значениями
        adjustedSize = Math.max(this.minSize, Math.min(this.maxSize, adjustedSize));

        return { size: adjustedSize, hasTrophyChance }; // Возвращаем размер и флаг
    }

    // Расчет веса на основе размера
    calculateWeight(size) {
        // Нормализуем размер в диапазоне от 0 до 1
        const normalizedSize = (size - this.minSize) / (this.maxSize - this.minSize);

        // Рассчитываем вес с учетом кубической зависимости
        const weight = this.minWeight + Math.pow(normalizedSize, 3) * (this.maxWeight - this.minWeight);

        // Добавляем случайное отклонение в пределах ±10%
        const deviation = weight * (Math.random() * 0.2 - 0.1); // От -10% до +10%
        return weight + deviation;
    }

    // Форматирование веса в граммы и килограммы
    formatWeight(weightKg) {
        const weightGrams = weightKg * 1000; // Переводим килограммы в граммы

        if (weightKg < 1) {
            return `${Math.round(weightGrams)} гр`; // Если меньше 1 кг, показываем только граммы
        } else {
            const kg = Math.floor(weightKg); // Целая часть (килограммы)
            const grams = Math.round(weightGrams % 1000); // Остаток (граммы)
            return `${kg} кг ${grams} гр`; // Формат: "X кг Y гр"
        }
    }
}