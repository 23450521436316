import { createApp } from 'vue' // Импортируйте createApp
import App from './components/App.vue'
import store from './scenes/shore/store' // Импортируем хранилище

// Создайте приложение с использованием createApp
const app = createApp(App)

// Подключите хранилище
app.use(store)

// Инициализация Telegram WebApp
// Проверка, что приложение запущено внутри Telegram
const isTelegram = () => {
    return window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData;
};

// Инициализация Telegram WebApp (только если запущено в Telegram)
if (isTelegram()) {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();
    tg.requestFullscreen();
    tg.enableClosingConfirmation();
    tg.disableVerticalSwipes();
    tg.lockOrientation();

    // Получаем данные инициализации
    const initData = tg.initData;

    // Парсим данные инициализации
    const params = new URLSearchParams(initData);
    const user = params.get('user');

    if (user) {
        const userData = JSON.parse(user);
        const userId = userData.id;
        const userName = userData.first_name;

        // Сохраняем данные пользователя в хранилище
        store.dispatch('setUser', { id: userId, name: userName });
    }
}

// Смонтируйте приложение
app.mount('#app')