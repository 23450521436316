import Core from "@/scenes/shore/classes/Core";

export const handlersMixin = {
    methods: {
        // Проверка, находится ли точка клика внутри кружка
        isWithinCircle(x, y, circle, radius) {
            const dx = x - circle.x
            const dy = y - circle.y
            return dx * dx + dy * dy <= radius * radius
        },

        // Обработка клика на canvas
        handleCanvasClick(event) {

            // Проверяем, если удочка выбрана, то забрасываем её
            if (this.selectedRod !== null && this.selectedRod.inHands === false) {
                this.selectedRod.castRod(event)
                //return
            }

            // const canvas = this.$refs.gameCanvas ИЗБАВИТЬСЯ
            // const rect = canvas.getBoundingClientRect()
            // const x = event.clientX - rect.left
            // const y = event.clientY - rect.top

            // Поймать клюющую рыбу или вынуть приманку
            // this.rods.forEach((rod) => {
            //     if (rod.castLocation && this.isWithinCircle(x, y, rod.castLocation, 15)) {
            //         if (rod.isBiting) {
            //             // Поимка рыбы
            //             this.catchingFish(rod) // Удалить данный метод вылавливания
            //         } else {
            //             // Вынимание приманки
            //             this.caughtMessage = '' // Очистить сообщение о рыбе
            //             this.removeBait(rod) // Вынуть приманку
            //         }
            //         this.floatAnimationStop(rod) // Прекращаем анимацию поклевки
            //         this.draw() // Перерисовываем canvas
            //     }
            // })
        },

        // Обработка нажатий клавиш
        handleKeyDown(event) {
            switch(event.code) {
                case 'KeyQ': this.baits[0].selectBait(); break // Выбираем приманку 1
                case 'KeyW': this.baits[1].selectBait(); break // Выбираем приманку 2
                case 'KeyE': this.baits[2].selectBait(); break // Выбираем приманку 3

                case 'Digit1': this.rods[0].selectRod(); break // Выбираем удочку 1
                case 'Digit2': this.rods[1].selectRod(); break // Выбираем удочку 2
                case 'Digit3': this.rods[2].selectRod(); break // Выбираем удочку 3
                //case 'Space': this.handleSpacePress(); break // Обработка нажатия пробела
                default: break // Ничего не делаем для других клавиш
            }
            if(event.code === 'KeyS') {
                // Начинаем наматывать леску
                if (this.selectedRod !== null && this.selectedRod.isReeling === false && this.selectedRod.isBiting === true) {
                    this.selectedRod.inHands = true // Взяли удочку в руки
                    this.selectedRod.isReeling = true // Начинаем наматывать леску
                    this.selectedRod.reelIn(Core.canvas.height, () => this.selectedRod?.isReeling || false)
                }
            }
            if(event.code === 'KeyA') {
                // Двигаем удочку влево
                if (this.selectedRod !== null && this.selectedRod.isReeling === false) {
                    this.selectedRod.move(-1, () => this.selectedRod?.isReeling || false)
                }
            }
            if(event.code === 'KeyD') {
                // Двигаем удочку право
                if (this.selectedRod !== null && this.selectedRod.isReeling === false) {
                    this.selectedRod.move(1, () => this.selectedRod?.isReeling || false)
                }
            }
        },

        // Обработка отжатия клавиш
        handleKeyUp(event) {
            if (event.code === 'KeyS') {
                // Останавливаем наматывание лески
                if (this.selectedRod !== null) {
                    this.selectedRod.isReeling = false // Останавливаем наматывание лески
                    this.selectedRod.stopReeling()
                }
            }
        },

        handleMouseMove() { // event убрано
            // // Если удочка выбрана, не сматывается и находится в руках
            // if (this.selectedRod !== null && this.selectedRod.isReeling === false && this.selectedRod.inHands === true) {
            //     // Получаем размеры и положение canvas
            //     const rect = event.target.getBoundingClientRect();
            //
            //     // Масштабируем координаты мыши с учетом размеров canvas
            //     const scaleX = Core.canvas.width / rect.width;  // Масштаб по X
            //
            //     // Рассчитываем координаты мыши внутри canvas
            //     const x = (event.clientX - rect.left) * scaleX - this.selectedRod.image.width / 2;
            //
            //     // Двигаем удочку
            //     this.selectedRod.move(x, () => this.selectedRod?.isReeling || false);
            // }
        }
    }
}