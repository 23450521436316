import Core from '@/scenes/shore/classes/Core'
import Rod from '@/scenes/shore/classes/Rod'

export default class FishingOutAnimation {
    constructor(rod) {
        this.rod = rod          // Удочка
        this.tension = 0        // Натяжение
        this.distance = 0       // Расстояние
        this.movedDistance = 0  // Пройденная дистанция
        this.speed = 0          // Скорость
        this.scanAngleStep = 10 // Период угла сканирования
        this.radius = 50        // Радиус сканирования
        this.directions = []    // Допустимые направлений
        this.scanPoints = []    // Точки сканирования
        this.direction = 0      // Выбранное случайное направление

        this.init()             // Запуск
    }

    // Запуск
    init() {
        // Обнуляем пройденое расстояние
        this.movedDistance = 0

        // Случайное расстояние от 20 до 50 пикселей
        this.distance = Math.random() * (50 - 20) + 20

        // Скорость движения
        this.speed = Math.random() * (1 - 0.2) + 0.2

        // Сканируем круг вокруг поплавка
        this.scan()
        
        // Анимируем
        this.animation()
    }

    // Анимация вываживания
    animation() {
        // Первый кадр
        requestAnimationFrame(() => this.frame())
    }

    // Кадр
    frame() {
        // Горизонтальное и вертикальное смещение за кадр
        const dx = Math.cos(this.direction * Math.PI / 180) * this.speed;
        const dy = Math.sin(this.direction * Math.PI / 180) * this.speed;

        // Если флаг движения сброшен, прекращаем движение
        if (this.rod.inHands === false) return;

        // Добавляем расстояние пройденное за кадр
        this.movedDistance += Math.sqrt(dx * dx + dy * dy);

        // Если прошли все расстояние - отбой
        if (this.movedDistance >= this.distance) {
            // Движение завершено, направляем в другую сторону
            this.init(); // Запуск анимации
            return;
        }

        // Допустимый запас в пикселях
        const margin = 2;

        // При допустимости движения в рамках поля ловли
        if (Rod._isInsideFishingArea(this.rod.castLocation.x + dx + margin, this.rod.castLocation.y + dy + margin)) {
            // Двигаем поплавок по оси X и Y
            this.rod.castLocation.x += dx;
            this.rod.castLocation.y += dy;
            this.rod.distanceToReel = (Core.canvas.height - this.rod.castLocation.y) / 10; // Дистанция заброса
        }

        // Вычисляем расстояние между удочкой и поплавком по оси X
        const deltaX = this.rod.castLocation.x - this.rod.x;
        const maxDistance = 300; // Максимальное расстояние для полного изгиба

        // Устанавливаем изгиб удочки, зависящий от deltaX и maxDistance
        this.rod.curve = Math.max(-100, Math.min(100, (deltaX / maxDistance) * 100)); // Устанавливаем изгиб удочки

        // Устанавливаем угол наклона удочки
        const maxAngle = 80; // Максимальный наклон влево и вправо
        const angleSign = deltaX < 0 ? 1 : -1; // Обратный знак для направления наклона
        const angleMagnitude = Math.min(Math.abs(deltaX / maxDistance) * maxAngle, maxAngle); // Рассчитываем величину наклона
        this.rod.angle = angleSign * angleMagnitude; // Устанавливаем угол с учетом обратного направления

        // Перерисовываем
        Core.draw();

        // Следующий кадр
        requestAnimationFrame(() => this.frame());
    }

    // Сканирование круга вокруг поплавка
    scan() {
        // Сбрасываем параметры
        this.scanPoints = [];
        this.directions = [];

        // Угол начала и конца для сканирования (в градусах)
        const startAngle = -135; // Угол влево вверх
        const endAngle = -45;     // Угол вправо вверх

        // Сканируем
        for (let angle = startAngle; angle <= endAngle; angle += this.scanAngleStep) {
            const dx = Math.cos(angle * Math.PI / 180) * this.radius;
            const dy = Math.sin(angle * Math.PI / 180) * this.radius;

            // Инвертируем dy, чтобы движение шло вверх
            const nextX = this.rod.castLocation.x + dx;
            const nextY = this.rod.castLocation.y + dy; // Инверсия для направления вверх

            // Проверяем, находится ли следующая позиция внутри области ловли
            const valid = Rod._isInsideFishingArea(nextX, nextY);
            this.scanPoints.push({ x: nextX, y: nextY, valid }); // Добавляем точку в массив с информацией о допустимости

            if (valid) {
                this.directions.push(angle); // Добавляем допустимый угол в массив
            }
        }

        // Если нет доступных направлений - отбой
        if (this.directions.length < 1) return;

        // Выбираем одно случайное
        this.direction = this.directions[Math.floor(Math.random() * this.directions.length)];
    }

}