<template>
  <img :src="imageSrc" alt="Fish Image">
</template>

<script>
export default {
  name: 'Fish',
  props: {
    imageName: {
      type: String,
      required: true
    }
  },
  computed: {
    imageSrc() {
      try {
        // Динамически загружаем изображение по имени
        return require(`@/scenes/shore/assets/images/fish/${this.imageName}.png`);
      } catch (e) {
        console.error(`Image not found: ${this.imageName}`);
        return '';
      }
    }
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
  height: auto;
}
</style>