export default class RodDrawer {
    constructor(floatImage) {
        this.floatImage = floatImage // Изображение поплавка
    }

    // Перерисовка удочек
    drawRods(ctx, rods) {
        rods.forEach((rod) => {
            if(rod.castLocation !== null) {
                this.drawFloat(ctx, rod)
                this.drawRod(ctx, rod)
                this.drawLine(ctx, rod)
            }
        })
    }

    // Отрисовываем поплавок
    drawFloat(ctx, rod) {
        let floatHeight = rod.floatHeight
        let cropHeight = this.floatImage.height - floatHeight // Высота видимой части поплавка

        ctx.drawImage(
            this.floatImage,
            0, 0, // Начало обрезки на изображении (верхний левый угол)
            this.floatImage.width, cropHeight, // Ширина и высота видимой части
            rod.castLocation.x - this.floatImage.width / 2, rod.castLocation.y - cropHeight, // Место размещения на холсте
            this.floatImage.width, cropHeight // Размеры видимой части на холсте
        )
    }

    // Отрисовываем удочку
    drawRod(ctx, rod) {
        ctx.save();

        // Высота и ширина изображения удочки
        const rodHeight = rod.image.height;
        const rodWidth = rod.image.width;

        // Задаем точку поворота - нижнюю часть удочки
        ctx.translate(rod.x, rod.y);

        // Проходим по каждому вертикальному отрезку удочки
        for (let i = 0; i < rodHeight; i++) {
            // Нормализуем позицию по высоте удочки (от 0 до 1)
            const t = i / rodHeight;

            // Функция, которая определяет смещение в зависимости от позиции по высоте
            // Используем синусоиду для плавного изгиба в середине
            const offsetX = Math.sin(t * Math.PI) * rod.curve / 5;

            ctx.drawImage(
                rod.image,
                0,  // Исходная координата по X для изображения
                i,  // Исходная координата по Y для сегмента удочки
                rodWidth,  // Ширина сегмента
                1,  // Высота сегмента (по одному пикселю)
                -offsetX, // Сдвиг X для создания дуги
                -rodHeight + i, // Текущая Y координата
                rodWidth,  // Ширина сегмента на Canvas
                1  // Высота сегмента на Canvas
            );
        }

        ctx.restore();
    }

    // Отрисовываем леску
    drawLine(ctx, rod) {
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)' // Цвет линии
        ctx.lineWidth = 1 // Толщина линии

        // Начинаем от верхнего конца удочки
        ctx.beginPath()
        ctx.moveTo(rod.x + rod.image.width / 2, rod.y - rod.image.height) // Начальная точка лески

        // Определяем конечную точку лески
        const floatX = rod.castLocation.x // X-координата поплавка
        const floatY = rod.castLocation.y // Y-координата поплавка

        // Проверяем натяжение лески
        if(rod.isReeling) {
            ctx.lineTo(floatX, floatY) // Прямое движение к поплавку
        } else {
            // Определяем контрольную точку для дуги
            const controlPointX = (rod.x + rod.castLocation.x) / 2 // Половина расстояния между удочкой и поплавком по X
            const controlPointY = Math.min(rod.y - rod.image.height, rod.castLocation.y) + 150 // Контрольная точка ниже линии, чтобы создать изгиб вниз
            ctx.quadraticCurveTo(controlPointX, controlPointY, rod.castLocation.x, rod.castLocation.y) // Ведем к поплавку
        }

        // Отрисовываем леску
        ctx.stroke()
    }
}
