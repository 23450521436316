import Core from '@/scenes/shore/classes/Core'
import Rod from '@/scenes/shore/classes/Rod'
import FishingOutAnimation from '@/scenes/shore/classes/FishingOutAnimation'

export default class BiteAnimation {
    constructor(rod) {
        this.rod = rod // Ссылка на объект удочки
        this.biteTime = null // Таймер для клёва
        this.floatAnimationInterval = null // Интервал анимации поплавка
        this.initialHeight = this.rod.floatHeight // Исходная высота поплавка
        this.maxFloatHeight = 30 // Максимальная глубина погружения поплавка
        this.maxCurve = 20 // Максимальный изгиб удочки
        this.fishingOutAnimation = null // Анимация вываживания
    }

    // Запланировать клёв
    scheduleBite() {
        clearTimeout(this.biteTime) // На случай перезаброса останавливаем анимации
        this.floatAnimationStop() // На случай перезаброса останавливаем анимации

        const minTime = 1000 // Минимум секунд
        const maxTime = 1000 // Максимум секунд
        const biteDelay = Math.random() * (maxTime - minTime) + minTime

        this.biteTime = setTimeout(() => {
            this.rod.isBiting = true
            this.initBait() // Начинаем клев
        }, biteDelay)
    }

    // Началась поклевка на удочке
    initBait() {
        if (!this.rod.castLocation) return // Если удочка не заброшена - отбой
        this.floatAnimationStop() // Останавливаем любые текущие анимации перед началом новой
        Core.draw() // Перерисовка фона и удочек

        // // Если я сматываю удочку
        // if(this.isReeling) {
        //     if (rod.distanceToReel > 0) {
        //         rod.reelIn(this.isReeling)
        //         //rod.lineTension += rod.fishStrength * 0.1 // Увеличиваем натяжение
        //         //rod.distanceToReel -= 0.1 // Уменьшаем расстояние до сматывания
        //         // if (rod.lineTension >= 100) {
        //         //     this.fishEscaped() // Рыба сорвалась
        //         // }
        //         //this.animateFishTowardsPlayer() // Анимация рыбы
        //     }
        // }

        this.initFloatAnimation() // Начинаем анимацию
    }

    // Инициализация анимации клюющего поплавка
    initFloatAnimation() {
        if (this.floatAnimationInterval !== null) return

        let toggle = true, // Для чередования подъем/опускание
            speedDown = 50, // Скорость опускания
            speedUp = 40, // Скорость подъема
            delay = 1000, // Пауза между циклами (1 секунда)
            currentDelay = 0, // Текущее значение для отслеживания паузы
            isAnimating = false, // Флаг анимации

            episodeCount = Math.floor(Math.random() * 5) + 1, // Случайное количество эпизодов от 1 до 5
            currentEpisode = 0 // Текущий эпизод

        this.rod.floatIsMoving = true // Отмечаем, что будем начинать движение после анимации прыгания поплавка
        this.rod.floatIsBaiting = true // Даем разрешение дергаться поплавку при поклевке

        let lastTime = 0 // Время последнего кадра

        const animate = (timestamp) => {
            if (!this.rod.floatIsBaiting) return // Если был перезаброс, то запрещаем анимацию

            if (!lastTime) {
                lastTime = timestamp // Инициализируем время первого кадра
            }

            const deltaTime = timestamp - lastTime // Время, прошедшее с предыдущего кадра
            lastTime = timestamp // Обновляем время последнего кадра

            // Если удочка в руках и тянем рыбу
            if(this.rod.inHands) {
                this.rod.floatHeight = this.initialHeight
                this.floatAnimationStop(this.rod) // Прекращаем анимацию поклевки
                //this.startFishingOut() // Запускаем анимацию вываживания
                this.fishingOutAnimation = new FishingOutAnimation(this.rod)
                return
            }

            // Если удочка установлена - Анимация поплавка (вниз/вверх)
            else if (toggle) {
                // Если в состоянии опускания поплавка
                this.rod.floatHeight += (speedDown * deltaTime) / 1000 // Учитываем deltaTime
                if (this.rod.floatHeight >= this.maxFloatHeight) {
                    this.rod.floatHeight = this.maxFloatHeight
                    toggle = false
                }
            } else {
                // Если в состоянии подъема поплавка
                this.rod.floatHeight -= (speedUp * deltaTime) / 1000 // Учитываем deltaTime
                if (this.rod.floatHeight <= this.initialHeight) {
                    this.rod.floatHeight = this.initialHeight
                    toggle = true
                    isAnimating = false // Завершаем анимацию
                    currentDelay = delay // Устанавливаем паузу

                    currentEpisode++ // Увеличиваем количество выполненных эпизодов

                    // Если все эпизоды завершены, запускаем движение в сторону
                    if (currentEpisode >= episodeCount) {
                        clearInterval(this.floatAnimationInterval) // Останавливаем эпизоды
                        this.floatAnimationInterval = null
                        this.rod.floatHeight = this.initialHeight // Возвращаем поплавок в исходное состояние
                        this.rod.curve = 0 // Удочка выпрямляется
                        this.startRandomMovement() // Запускаем случайное движение поплавка
                        return
                    }

                    // После завершения анимации начинаем движение
                    //this.startRandomMovement()
                    return
                }
            }

            // Вычисляем изгиб удочки
            this.calculateRodCurve()

            // Перерисовываем поплавок с текущими параметрами
            Core.draw()

            if (isAnimating) {
                requestAnimationFrame(animate) // Продолжаем анимацию, если она активна
            }
        }

        // Запускаем цикл с паузами
        this.floatAnimationInterval = setInterval(() => {
            if (currentDelay > 0) {
                currentDelay -= 10 // Отсчитываем паузу
            } else if (!isAnimating) {
                isAnimating = true // Начинаем новую анимацию
                requestAnimationFrame(animate)
            }
        }, 16)
    }

    // Анимация вываживания
    startFishingOut() {
        this.rod.curve = 50 // Выпрямляем удочку
        const randomDistance = Math.random() * (50 - 20) + 20, // Случайное расстояние от 20 до 50 пикселей
            scanAngleStep = 10, // Угол сканирования (каждые 5 градусов)
            speed = Math.random() * (10 - 3) + 3, // Скорость движения
            radius = 300 // Радиус сканирования совпадает с расстоянием, на которое будет двигаться поплавок

        let totalMovedDistance = 0 // Общая пройденная дистанция
        const validDirections = [] // Массив допустимых направлений
        this.scanPoints = [] // Массив для запоминания точек сканирования

        // Сканируем круг вокруг поплавка
        for (let angle = 0; angle < 360; angle += scanAngleStep) {
            const dx = Math.cos(angle * Math.PI / 180) * radius // Горизонтальное смещение
            const dy = Math.sin(angle * Math.PI / 180) * radius // Вертикальное смещение

            const nextX = this.rod.castLocation.x + dx
            const nextY = this.rod.castLocation.y + dy

            // Проверяем, находится ли следующая позиция внутри области ловли
            const valid = Rod._isInsideFishingArea(nextX, nextY)
            this.scanPoints.push({ x: nextX, y: nextY, valid }) // Добавляем точку в массив с информацией о допустимости

            if (valid) {
                validDirections.push(angle) // Добавляем допустимый угол в массив
            }
        }

        // Если есть допустимые направления, выбираем одно случайное
        if (validDirections.length > 0) {
            const randomDirection = validDirections[Math.floor(Math.random() * validDirections.length)]

            let lastTime = 0

            const moveFloat = (timestamp) => {

                if (!lastTime) {
                    lastTime = timestamp // Инициализируем время первого кадра
                }

                const deltaTime = timestamp - lastTime // Время, прошедшее с предыдущего кадра
                lastTime = timestamp // Обновляем время последнего кадра

                const distanceThisFrame = (speed * deltaTime) / 1000 // Расстояние, которое нужно пройти за этот кадр

                // Рассчитываем смещение по X и Y
                const angle = randomDirection * Math.PI / 180 // Угол в радианах
                const dx = Math.cos(angle) * distanceThisFrame // Смещение по X
                const dy = Math.sin(angle) * distanceThisFrame // Смещение по Y

                if (!this.rod.floatIsMoving || this.rod.castLocation === null) return // Если флаг движения сброшен, прекращаем движение

                totalMovedDistance += distanceThisFrame // Добавляем к общей пройденной дистанции

                if (totalMovedDistance < randomDistance) {
                    const margin = 2 // Допустимый запас в пикселях

                    if (Rod._isInsideFishingArea(this.rod.castLocation.x + dx + margin, this.rod.castLocation.y + dy + margin)) {
                        this.rod.castLocation.x += dx // Двигаем поплавок по оси X
                        this.rod.castLocation.y += dy // Двигаем поплавок по оси Y
                    }

                    Core.draw() // Перерисовываем

                    requestAnimationFrame(moveFloat) // Продолжаем движение
                } else {
                    // Движение завершено, направляем в другую сторону
                    this.startFishingOut() // Запускаем анимацию вываживания
                }
            }

            requestAnimationFrame(moveFloat)
        }
    }

    // Анимация поклевки
    startRandomMovement() {
        this.rod.curve = 0 // Выпрямляем удочку
        const randomDistance = Math.random() * (50 - 20) + 20, // Случайное расстояние от 20 до 50 пикселей
              scanAngleStep = 10, // Угол сканирования (каждые 5 градусов)
              speed = 50, // Скорость движения рыбы
              radius = 300 // Радиус сканирования совпадает с расстоянием, на которое будет двигаться поплавок

        let totalMovedDistance = 0 // Общая пройденная дистанция
        const validDirections = [] // Массив допустимых направлений
        this.scanPoints = [] // Массив для запоминания точек сканирования

        // Сканируем круг вокруг поплавка
        for (let angle = 0; angle < 360; angle += scanAngleStep) {
            const dx = Math.cos(angle * Math.PI / 180) * radius // Горизонтальное смещение
            const dy = Math.sin(angle * Math.PI / 180) * radius // Вертикальное смещение

            const nextX = this.rod.castLocation.x + dx
            const nextY = this.rod.castLocation.y + dy

            // Проверяем, находится ли следующая позиция внутри области ловли
            const valid = Rod._isInsideFishingArea(nextX, nextY)
            this.scanPoints.push({ x: nextX, y: nextY, valid }) // Добавляем точку в массив с информацией о допустимости

            if (valid) {
                validDirections.push(angle) // Добавляем допустимый угол в массив
            }
        }

        // Если есть допустимые направления, выбираем одно случайное
        if (validDirections.length > 0) {
            const randomDirection = validDirections[Math.floor(Math.random() * validDirections.length)]

            let lastTime = 0

            const moveFloat = (timestamp) => {
                if (!this.rod.floatIsMoving || this.rod.castLocation === null) return // Если флаг движения сброшен, прекращаем движение

                if (!lastTime) {
                    lastTime = timestamp // Инициализируем время первого кадра
                }

                const deltaTime = timestamp - lastTime // Время, прошедшее с предыдущего кадра
                lastTime = timestamp // Обновляем время последнего кадра

                const distanceThisFrame = (speed * deltaTime) / 1000 // Расстояние, которое нужно пройти за этот кадр

                // Рассчитываем смещение по X и Y
                const angle = randomDirection * Math.PI / 180 // Угол в радианах
                const dx = Math.cos(angle) * distanceThisFrame // Смещение по X
                const dy = Math.sin(angle) * distanceThisFrame // Смещение по Y

                totalMovedDistance += distanceThisFrame // Добавляем к общей пройденной дистанции

                if (totalMovedDistance < randomDistance) {
                    const margin = 2 // Допустимый запас в пикселях

                    if (Rod._isInsideFishingArea(this.rod.castLocation.x + dx + margin, this.rod.castLocation.y + dy + margin)) {
                        this.rod.castLocation.x += dx // Двигаем поплавок по оси X
                        this.rod.castLocation.y += dy // Двигаем поплавок по оси Y
                    }

                    Core.draw() // Перерисовываем

                    requestAnimationFrame(moveFloat) // Продолжаем движение
                } else {
                    // Движение завершено, можем сбросить или остановить
                    this.endBait(this.rod) // Рыба сорвалась
                }
            }

            requestAnimationFrame(moveFloat)
        }
    }

    // Рыба сорвалась: Завершаем поклевку после анимации всех движений поплавка
    endBait() {
        //this.baitEaten(1, 3, rod) // Проверяем, съели ли наживку с шансом 1 из 3
        this.rod.isBiting = false

        // Если наживка не съедена, планируем новый клёв
        if (this.rod.castLocation && this.rod.bait !== null) {
            this.scheduleBite(this.rod)
        }

        Core.draw() // Перерисовываем после окончания клёва

        this.floatAnimationStop(this.rod) // Прекращаем анимацию поклевки
    }

    // Прекращаем анимацию поклевки
    floatAnimationStop() {
        if (this.floatAnimationInterval) {
            clearInterval(this.floatAnimationInterval)
            this.floatAnimationInterval = null
        }

        // Останавливаем движение поплавка
        this.rod.floatIsBaiting = false // Запрет на анимацию прыгания поплавка
        this.rod.floatIsMoving = false // Останавливаем движение поплавка
        this.rod.floatHeight = 20 // Возвращаем поплавок в исходное состояние
        this.rod.curve = 0 // Удочка выпрямляется

        Core.draw() // Обновляем интерфейс
    }

    // Метод для вычисления изгиба удочки
    // calculateRodCurve() {
    //     let immersionPercentage = (this.rod.floatHeight - this.initialHeight) / (this.maxFloatHeight - this.initialHeight)
    //     this.rod.curve = Math.min(this.maxCurve * immersionPercentage, this.maxCurve) // Пропорциональный изгиб
    // }
    calculateRodCurve() {
        let immersionPercentage = (this.rod.floatHeight - this.initialHeight) / (this.maxFloatHeight - this.initialHeight);
        immersionPercentage = Math.max(0, Math.min(1, immersionPercentage)); // Ограничиваем значение от 0 до 1

        // Плавное изменение изгиба
        let targetCurve = this.maxCurve * immersionPercentage;
        this.rod.curve += (targetCurve - this.rod.curve) * 0.1; // Добавляем плавность (коэффициент 0.1)
    }

}