import Core from './Core'
import BiteAnimation from "@/scenes/shore/classes/BiteAnimation";

export default class Rod {
    constructor(name, index) {
        this.name = name // Название удочки
        this.index = index // Номер размещения на экране
        this.x = 150 + (30 * this.index) // Позиция по оси X (визуальная самой удочки)
        this.y = Core.canvas.height // Позиция по оси Y (визуальная самой удочки)
        this.castLocation = null // Место заброса и позиция поплавка
        this.isBiting = false // Есть ли поклевка
        this.isReeling = false // Скручиваем ли леску
        this.inHands = false // В руках ли удочка - если начал подсекать то удочка остается в руках до перезаброса
        this.biteTime = null // Время поклевки
        this.bait = null // Приманка
        this.floatHeight = 20 // Высота поплавка
        this.floatIsMoving = false // Движется ли поплавок
        this.floatIsBaiting = false // Дает ли поплавок дергаться при поклевке
        this.curve = 0 // Изгиб удочки
        this.lineTension = 0 // Натяжение лески
        this.fishStrength = Math.random() * 100 // Сила рыбы
        this.distanceToReel = 0 // Дистанция до сматывания
        this.animationFrameId = null // Хранит ID анимации для остановки
        this.image = null // Изображение удочки
        this.biteAnimation = new BiteAnimation(this) // Для объекта анимации
    }

    // Проверка, находится ли точка внутри области для заброса (многоугольник)
    static _isInsideFishingArea(x, y) {
        const points = Core.fishingArea
        let inside = false

        for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
            const xi = points[i].x, yi = points[i].y
            const xj = points[j].x, yj = points[j].y

            const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
            if (intersect) inside = !inside
        }

        return inside
    }

    // Выбрать удочку
    selectRod() {
        const selectedRod = Core.store.state.selectedRod // Выбранная удочка
        const selectedBait = Core.store.state.selectedBait // Выбранная приманка

        // Поклевка - выбать удочку чтобы тащить
        if(this.isBiting === true) {
            Core.store.dispatch('setSelectedRod', this)
            return
        }

        // // Поклевка - ловить (вытаскивать рыбу)
        // if(rod.isBiting === true) {
        //     selectedRod = null
        //     this.floatAnimationStop(rod)
        //     this.catchingFish(rod)
        //     return
        // }

        // Насадить приманку
        if(selectedBait !== null && !selectedBait.setBait(this)) return // Насаживаем приманку на текущую удочку (если получается - то прекращаем дальнейшее выполнение)

        // Выбрать удочку или отменить выбор
        if(selectedRod === this) {
            Core.store.dispatch('setSelectedRod', null)
        } else {
            Core.store.dispatch('setSelectedRod', this)
        }
    }

    // Заброс удочки по клику
    castRod(event) {
        // Забросили удочку - установили ее убрав из рук
        this.inHands = false

        // Если удочка не выбрана или не насажена приманка - отбой
        if (Core.store.state.selectedRod === null || this.bait === null) return

        // Получаем размеры и положение canvas
        const rect = Core.canvas.getBoundingClientRect();

        // Масштабируем координаты клика с учетом размеров canvas
        const scaleX = Core.canvas.width / rect.width;  // Масштаб по X
        const scaleY = Core.canvas.height / rect.height; // Масштаб по Y

        // Рассчитываем координаты клика внутри canvas
        const x = (event.clientX - rect.left) * scaleX;
        const y = (event.clientY - rect.top) * scaleY;

        // Проверка на то, что место заброса в пределах разрешенной области
        if (Rod._isInsideFishingArea(x, y)) {
            //this.floatAnimationStop(selectedRod) // Прекращаем анимацию поклевки

            this.castLocation = { x, y } // Запоминаем координаты заброса
            this.distanceToReel = (Core.canvas.height - y) / 10 // Дистанция заброса
            this.isBiting = false // Сбрасываем клёв
            //Core.store.dispatch('setSelectedRod', null) // Снимаем выбор удочки

            // Планируем клёв через случайный промежуток времени
            this.biteAnimation.scheduleBite()

            Core.draw() // Перерисовываем canvas
        }
    }

    // Сматывание лески
    reelIn(canvasHeight, isReelingCallback, speed = 1.5) {
        const targetY = canvasHeight; // Целевая позиция Y у основания удочки

        const animate = () => {
            if (this.castLocation) {
                // Разница по координатам между удочкой и текущей позицией поплавка
                const deltaX = this.x - this.castLocation.x;
                const deltaY = targetY - this.castLocation.y;

                // Дистанция между удочкой и поплавком
                const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

                // Если уже почти дотянули до удочки, завершаем
                if (distance < 1) {
                    this.distanceToReel = 0;
                    cancelAnimationFrame(this.animationFrameId); // Остановка анимации
                    Core.store.dispatch('setSelectedRod', this);
                    this.biteAnimation.floatAnimationStop();

                    // Если была поклевка, начинаем выуживание рыбы
                    if (this.isBiting === true) { this.bait.catchingFish(this); }
                    return;
                }

                // Вычисляем смещения по `x` и `y` для плавного сматывания
                const moveSpeed = 0.6 * speed; // Скорость сматывания
                this.castLocation.x += (deltaX / distance) * moveSpeed;
                this.castLocation.y += (deltaY / distance) * moveSpeed;

                // Обновляем расстояние до берега
                this.distanceToReel = (targetY - this.castLocation.y) / 10;
                Core.draw();
            }

            // Если продолжаем сматывание
            if (isReelingCallback()) {
                this.animationFrameId = requestAnimationFrame(animate);
            }
        };

        // Запуск анимации
        this.animationFrameId = requestAnimationFrame(animate);
    }

    stopReeling() {
        // Остановка текущей анимации
        cancelAnimationFrame(this.animationFrameId)
    }

    // Перемещение удочки с учетом скорости
    // move(speed, isReelingCallback) {
    //     if (!isReelingCallback()) {
    //         this.x += speed; // Учитываем скорость
    //         Core.draw();
    //     }
    // }
    // move(speed) {
    //     if(this.x + speed < 50 || this.x + speed > 1250) return
    //     this.x += speed; // Учитываем скорость
    //     Core.draw();
    // }

    move(speed) {
        const canvas = Core.canvas;
        const canvasWidth = canvas.width; // Ширина канваса
        const parent = canvas.parentElement; // Родительский контейнер канваса
        const visibleWidth = parent.clientWidth; // Видимая ширина контейнера канваса

        // Динамически рассчитываем отступы
        const minVisibleWidth = 520; // Минимальная ширина видимой области (например, для мобильных устройств)
        // const maxVisibleWidth = 1300; // Максимальная ширина видимой области (например, для десктопов)

        // Формула для scrollMarginRight: уменьшается с увеличением visibleWidth
        const scrollMarginRight = Math.max(-150, 50 - (visibleWidth - minVisibleWidth) * 0.5);

        // Формула для scrollMarginLeft: увеличивается с увеличением visibleWidth
        const scrollMarginLeft = Math.min(200, 120 + (visibleWidth - minVisibleWidth) * 0.5);

        // Проверка на выход за границы канваса
        if (this.x + speed < 50 || this.x + speed > canvasWidth - 50) {
            return; // Не выходим за границы канваса
        }

        // Обновляем позицию удочки
        this.x += speed;

        // Получаем текущий скролл
        const scrollLeft = parent.scrollLeft;
        const scrollRight = scrollLeft + visibleWidth;

        // Если удочка приближается к правому краю видимой области
        if (this.x > scrollRight - scrollMarginRight) {
            const targetScroll = this.x - (visibleWidth - scrollMarginRight);
            parent.scrollTo({ left: targetScroll, behavior: 'auto' });
        }
        // Если удочка приближается к левому краю видимой области
        else if (this.x < scrollLeft + scrollMarginLeft) {
            const targetScroll = this.x - scrollMarginLeft;
            parent.scrollTo({ left: targetScroll, behavior: 'auto' });
        }

        // Перерисовываем канвас
        Core.draw();
    }
}
