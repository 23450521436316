<template>
  <Shore/>
</template>

<script>
import Shore from '../scenes/shore/Shore.vue'

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Shore
  }
}
</script>

<style>
html, body {
  background: #000;
  color: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Глобальные стили для запрета выделения текста */
* {
  user-select: none; /* Запрет выделения текста */
  -webkit-user-select: none; /* Для Safari */
}
/* Разрешить выделение текста для определенных элементов */
.allow-select {
  user-select: text; /* Разрешить выделение текста */
  -webkit-user-select: text; /* Для Safari */
}
</style>
