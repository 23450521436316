import RodDrawer from './RodDrawer'
import { ref } from 'vue'

export default class Core {
    static _canvas = null // Статическое свойство для хранения ссылки на canvas
    static _log = ref('') // Лог
    static _scanPoints = [] // Массив для хранения точек сканирования
    static _store = null // Статическое свойство для хранения единого экземпляра store
    static _floatImage = {} // Переменная для хранения изображения поплавка
    static _rodImages = [] // Массив для хранения изображений удочек
    static _rodDrawer = null // Экземпляр RodDrawer
    static _caughtMessage = ref('') // Сообщение о пойманной рыбе

    static _fps = 65; // Желаемое количество кадров в секунду
    static _lastDrawTime = 0; // Время последнего вызова draw
    static _frameInterval = 1000 / Core._fps; // Интервал между кадрами в миллисекундах
    static _isDrawing = false; // Флаг для отслеживания состояния отрисовки

    // Зона ловли
    static _fishingArea = [
        { x: 5, y: 305 },
        { x: 1200, y: 300 },
        { x: 920, y: 450 },
        { x: 1000, y: 580 },
        { x: 5, y: 580 },
    ]

    // Статический сеттер и геттер для canvas
    static get canvas() { return this._canvas }
    static set canvas(data) { this._canvas = data }

    static get log() { return this._log }
    static set log(value) { this._log = value }

    static get scanPoints() { return this._scanPoints }
    static set scanPoints(value) { this._scanPoints = value }

    static get store() { return this._store }
    static set store(value) { this._store = value }

    static get floatImage() { return this._floatImage }
    static set floatImage(value) { this._floatImage = value }

    static get rodImages() { return this._rodImages }

    static get fishingArea() { return this._fishingArea }

    static get rodDrawer() { return this._rodDrawer }
    static set rodDrawer(value) { this._rodDrawer = value }

    static get caughtMessage() { return this._caughtMessage }
    static set caughtMessage(value) { this._caughtMessage.value = value }

    static get fps() { return this._fps; }
    static set fps(value) {
        this._fps = value;
        this._frameInterval = 1000 / value; // Обновляем интервал при изменении FPS
    }

    static initialize(store) {
        this.store = store // Инициализируем хранилище
        this.loadImages() // Загружаем изображения
        this.rodDrawer = new RodDrawer(this.floatImage); // Инициализация RodDrawer
    }

    // Загрузка изображений удочек
    static loadImages() {
        for (let i = 0; i < this.store.state.rods.length; i++) {
            const img = new Image()
            img.src = require('@/scenes/shore/assets/images/rod.png') // Путь к изображению
            //this.rodImages.push(img)
            this.store.state.rods[i].image = img
        }
        const img = new Image()
        img.src = require('@/scenes/shore/assets/images/float.png') // Путь к изображению
        this.floatImage = img
    }

    // Отрисовка на canvas
    // Отрисовка на canvas с ограничением по FPS
    static draw() {
        if (!this._isDrawing) {
            this._isDrawing = true; // Устанавливаем флаг отрисовки
            const drawFrame = (timestamp) => {
                // Ограничение по FPS
                if (timestamp - this._lastDrawTime >= this._frameInterval) {
                    this._lastDrawTime = timestamp;

                    const ctx = this.canvas.getContext('2d');

                    // Отрисовка фона
                    const bgImage = new Image();
                    bgImage.src = require('@/scenes/shore/assets/images/bg1.jpg');
                    bgImage.onload = () => {
                        ctx.drawImage(bgImage, 0, 0, this.canvas.width, this.canvas.height);

                        // Отрисовка удочек
                        this.rodDrawer.drawRods(ctx, this.store.state.rods);

                        // Отрисовка области ловли (если в режиме отладки)
                        this.drawFishingArea(ctx);

                        // Отрисовка точек сканирования
                        this.drawScanPoints(ctx);
                    };
                }

                // Запуск следующего кадра
                requestAnimationFrame(drawFrame);
            };

            // Запуск первого кадра
            requestAnimationFrame(drawFrame);
        }
    }
    // static draw() {
    //     const ctx = this.canvas.getContext('2d')
    //     const bgImage = new Image()
    //     bgImage.src = require('@/scenes/shore/assets/images/bg1.jpg')
    //     bgImage.onload = () => {
    //         ctx.drawImage(bgImage, 0, 0, this.canvas.width, this.canvas.height)
    //         this.rodDrawer.drawRods(ctx, this.store.state.rods) // Перерисовываем удочки
    //         this.drawFishingArea(ctx) // Рисуем область ловли, если в режиме отладки
    //         this.drawScanPoints(ctx) // Рисуем точки сканирования
    //     }
    // }

    // Показать лог
    static setLog(text) {
        this.log.value = text // Устанавливаем лог
    }

    // Добавить лог
    static addLog(text) {
        this.log.value = text + ' | ' + this.log.value // Обновляем лог
    }

    // Метод для отрисовки области ловли
    static drawFishingArea(ctx) {
        if (!this.store.state.debugMode) return // Если режим отладки выключен, выходим

        ctx.strokeStyle = 'red' // Устанавливаем цвет линий
        ctx.lineWidth = 2 // Устанавливаем ширину линий
        ctx.beginPath() // Начинаем новый путь

        // Перебираем все точки в fishingArea
        this.fishingArea.forEach((point, index) => {
            if (index === 0) {
                ctx.moveTo(point.x, point.y) // Перемещаемся к первой точке
            } else {
                ctx.lineTo(point.x, point.y) // Рисуем линию к текущей точке
            }
        })

        // Замыкаем линию, возвращаясь к первой точке
        ctx.lineTo(this.fishingArea[0].x, this.fishingArea[0].y)
        ctx.stroke() // Рисуем линии
    }

    // Метод для рисования точек сканирования
    static drawScanPoints(ctx) {
        if (!this.store.state.debugMode) return // Если режим отладки выключен, выходим
        if(this.store.state.selectedRod) {
            if(this.store.state.selectedRod.biteAnimation.fishingOutAnimation) {
                if(this.store.state.selectedRod.biteAnimation.fishingOutAnimation.scanPoints) {
                    this.store.state.selectedRod.biteAnimation.fishingOutAnimation.scanPoints.forEach(point => {
                        ctx.beginPath()
                        ctx.arc(point.x, point.y, 2, 0, Math.PI) // Рисуем окружность радиусом 2 пикселя
                        ctx.fillStyle = point.valid ? 'white' : 'red' // Устанавливаем цвет в зависимости от допустимости
                        ctx.fill()
                    })
                }
            }
        }
    }
}
