import Core from '@/scenes/shore/classes/Core'
import WaterBody from '@/scenes/shore/classes/WaterBody'
import Fish from '@/scenes/shore/classes/Fish'

export default class Bait {
    constructor(name, count) {
        this.name = name // Название приманки
        this.count = count // Кол-во
    }

    // Выбрать приманку
    selectBait() {
        Core.store.dispatch('setSelectedRod', null) // Отменить выбор удочки

        if(this.count < 1) return // Если приманки не осталось - не даем выбрать

        // Выбрать приманку или отменить выбор
        if(Core.store.state.selectedBait === this) {
            Core.store.dispatch('setSelectedBait', null)
        } else {
            Core.store.dispatch('setSelectedBait', this)
        }
    }

    // Насадить приманку
    setBait(rod) {
        const selectedBait = Core.store.state.selectedBait // Выбранная приманка

        if(selectedBait === null) return // Если наживка не выбрана не даем наживить
        if(rod.bait !== null) return // Если наживка уже есть то не даем наживить
        this.removeBait(rod) // Вынуть приманку для перезаброса
        rod.bait = selectedBait // Установить приманку на удочку
        selectedBait.count-- // Уменьшить кол-во применки
        Core.store.dispatch('setSelectedBait', null) // Отменить выбор приманки
        Core.store.dispatch('setSelectedRod', null) // Отменить выбор удочки чтобы выбралась та на которую наживили приманку
        Core.draw()
        return true
    }

    // Снять приманку с удочки
    removeBait(rod) {
        rod.castLocation = null // Убираем точку заброса
        rod.isBiting = false // Сбрасываем клёв
        rod.inHands = false // Убираем удочку из рук
    }

    // Поимка рыбы
    catchingFish(rod) {
        // Данные о рыбах
        const fishesData = [
            // Вес мин-макс / Размер мин-макс / Цена кг / Сопротивляемость / Шанс срыва
            new Fish('Красноперка', 0.1, 1.5, 7, 25, 100, 30, 15),
            new Fish('Линь', 0.1, 2.0, 7, 30, 500, 60, 50),
            new Fish('Берш', 0.5, 2.0, 15, 25, 600, 60, 30),
            new Fish('Бычок', 0.05, 0.3, 7, 15, 50, 20, 40),
            new Fish('Вобла', 0.1, 2.5, 10, 25, 400, 40, 25),
            new Fish('Голавль', 0.3, 3.5, 10, 40, 300, 70, 35),
            new Fish('Горбуша', 0.8, 7, 20, 60, 900, 75, 20),
            new Fish('Ерш', 0.02, 0.2, 6, 15, 50, 15, 50),
            new Fish('Карась', 0.1, 2.0, 7, 30, 200, 50, 30),
            new Fish('Карп', 0.5, 30.0, 15, 50, 350, 70, 25),
            new Fish('Кета', 1.5, 10.0, 20, 70, 1400, 80, 15),
            new Fish('Лещ', 0.3, 4.0, 10, 40, 200, 60, 35),
            new Fish('Лосось', 2.0, 15, 20, 120, 1300, 90, 10),
            new Fish('Муксун', 0.8, 10, 20, 70, 1200, 75, 20),
            new Fish('Налим', 0.3, 6, 20, 80, 700, 65, 30),
            new Fish('Нельма', 1.5, 10, 20, 100, 1500, 85, 15),
            new Fish('Окунь', 0.1, 3, 5, 30, 300, 50, 30),
            new Fish('Пескарь', 0.02, 0.25, 6, 15, 50, 10, 60),
            new Fish('Плотва', 0.05, 2, 8, 25, 150, 30, 20),
            new Fish('Семга', 2.0, 15.0, 20, 120, 2000, 90, 10),
            new Fish('Сиг', 0.3, 5.0, 30, 20, 700, 60, 25),
            new Fish('Сом', 3.0, 100.0, 30, 200, 700, 95, 5),
            new Fish('Судак', 0.8, 7.0, 15, 50, 500, 80, 20),
            new Fish('Толстолобик', 1.0, 20, 20, 80, 600, 70, 25),
            new Fish('Форель радужная', 0.3, 8, 20, 60, 1000, 75, 20),
            new Fish('Щука', 0.8, 10.0, 15, 100, 700, 80, 40),
            new Fish('Язь', 0.3, 3, 15, 40, 350, 65, 30),
        ];

        // Данные о водоемах
        const waterBodies = [
            new WaterBody('Река Москва', [
                {
                    fish: fishesData.find(fish => fish.name === 'Плотва'),
                    catchChance: 40, // Шанс улова (40%)
                    sizePercentage: 50, // Средний размер (50% от диапазона)
                    activeTimes: [{ start: 0, end: 23 }] // Круглосуточно
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Окунь'),
                    catchChance: 30,
                    sizePercentage: 60,
                    activeTimes: [{ start: 0, end: 23 }] // Круглосуточно
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Щука'),
                    catchChance: 10,
                    sizePercentage: 70,
                    activeTimes: [{ start: 0, end: 23 }] // Активна с 10:00 до 14:00
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Бычок'),
                    catchChance: 20, // Шанс улова (20%)
                    sizePercentage: 50, // Средний размер (50% от диапазона)
                    activeTimes: [{ start: 0, end: 23 }] // Круглосуточно
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Вобла'),
                    catchChance: 25, // Шанс улова (25%)
                    sizePercentage: 50, // Средний размер (50% от диапазона)
                    activeTimes: [{ start: 6, end: 18 }] // Активна с 6:00 до 18:00
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Карась'),
                    catchChance: 35, // Шанс улова (35%)
                    sizePercentage: 50, // Средний размер (50% от диапазона)
                    activeTimes: [{ start: 0, end: 23 }] // Круглосуточно
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Лещ'),
                    catchChance: 30, // Шанс улова (30%)
                    sizePercentage: 60, // Средний размер (60% от диапазона)
                    activeTimes: [{ start: 6, end: 20 }] // Активен с 6:00 до 20:00
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Пескарь'),
                    catchChance: 15, // Шанс улова (15%)
                    sizePercentage: 50, // Средний размер (50% от диапазона)
                    activeTimes: [{ start: 0, end: 23 }] // Круглосуточно
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Линь'),
                    catchChance: 3, // Шанс улова (3%)
                    sizePercentage: 50, // Средний размер (50% от диапазона)
                    activeTimes: [{ start: 0, end: 12 }] // Круглосуточно
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Красноперка'),
                    catchChance: 60, // Шанс улова (60%)
                    sizePercentage: 50, // Средний размер (50% от диапазона)
                    activeTimes: [{ start: 0, end: 23 }] // Круглосуточно
                },
            ]),
            new WaterBody('Озеро Сенеж', [
                {
                    fish: fishesData.find(fish => fish.name === 'Карп'),
                    catchChance: 50,
                    sizePercentage: 60,
                    activeTimes: [{ start: 6, end: 12 }]
                },
                {
                    fish: fishesData.find(fish => fish.name === 'Лещ'),
                    catchChance: 30,
                    sizePercentage: 50,
                    activeTimes: [{ start: 14, end: 20 }]
                }
            ]),
            // Добавьте другие водоемы по аналогии
        ];

        const currentHour = new Date().getHours(); // Текущий час
        const currentWaterBody = waterBodies[0]; // Используем первый водоем (можно выбрать другой)

        const caughtFish = currentWaterBody.getRandomFish(currentHour);

        if (caughtFish) {
            const fish = caughtFish.fish;
            const sizePercentage = caughtFish.sizePercentage; // Получаем sizePercentage из данных водоема
            const { size, hasTrophyChance } = fish.getAdjustedSize(sizePercentage); // Получаем размер и флаг
            const weightKg = fish.calculateWeight(size); // Рассчитываем вес в кг
            const formattedWeight = fish.formatWeight(weightKg); // Форматируем вес
            const price = (weightKg * fish.pricePerKg).toFixed(2); // Рассчитываем стоимость

            // Проверяем, является ли рыба трофеем (размер > 90% от максимального)
            const isTrophy = size >= fish.maxSize * 0.90;

            // Формируем сообщение о шансе и результате
            let trophyMessage = '';
            if (hasTrophyChance) {
                trophyMessage = '<br><strong>Шанс на трофей!</strong>';
                if (isTrophy) {
                    trophyMessage += '<br><strong>УРА ТРОФЕЙ!</strong>';
                } else {
                    trophyMessage += '<br><strong>Не повезло!</strong>';
                }
            }

            Core.store.dispatch('openDialog', {
                title: 'Поймана рыба!',
                fishName: fish.name,
                message: `Вы поймали: ${fish.name}!<br>
                      Размер: ${size.toFixed(1)} см<br>
                      Вес: ${formattedWeight}<br>
                      Стоимость: ${price} руб${trophyMessage}`
            });
        } else {
            Core.store.dispatch('openDialog', {
                title: 'Неудача!',
                message: 'Рыба сорвалась или не клюнула.'
            });
        }

        this.baitEaten(2, 3, rod);
        this.removeBait(rod);
    }

    // Проверяем, съели ли наживку с заданным шансом
    baitEaten(chance1, chance2, rod) {
        if (Math.random() < chance1 / chance2) {
            if(rod.bait.count - 1 === -1)
                rod.bait = null
            else
                rod.bait.count--
        }
    }
}