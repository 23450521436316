<template>
  <div class="container">
    <!-- Верхний блок -->
    <div class="top-block">
      <!-- Здесь можно выводить данные об игре -->
      <div>
        <!-- Данные пользователя -->
        <div class="user-info" v-if="user.name">
          <div>Игрок: {{ user.name }}</div>
          <div>ID: {{ user.id }}</div>
        </div>
        <!-- Сообщение о пойманной рыбе -->
        <div v-if="caughtMessage" class="message">{{ caughtMessage }}</div>
        <!-- Лог -->
        <div v-if="log" class="log">{{ log }}</div>
      </div>
    </div>

    <div class="center-block">
      <!-- Canvas для отрисовки игры -->
      <canvas ref="gameCanvas" @click="handleCanvasClick" @mousemove="handleMouseMove" width="1300" height="584"></canvas>
    </div>

    <!-- Нижний блок -->
    <div class="bottom-block">
      <!-- Интерфейс для выбора удочек -->
      <div class="interface">
        <!-- Насадка -->
        <div class="baits">
          <div class="bait" v-for="(bait, index) in baits" :key="index" :class="{ selected: selectedBait === bait }" @click="bait.selectBait()">{{ bait.name }} ({{ bait.count }})</div>
        </div>

        <div class="rods">
          <div class="rod-container" v-for="(rod, index) in rods" :key="index">
            <!-- Удочки -->
            <div class="rod"
                 :class="{
                   selected: selectedRod === rod,
                   'is-biting': rod.isBiting
                 }"
                 @click="rod.selectRod()">
              <!-- Полоса натяжки лески -->
              <div class="tension-bar">
                <div class="tension-fill" :style="{ width: rod.lineTension + '%' }"></div>
              </div>
              <div class="rod-info">
                <div class="rod-name">{{ rod.name }}</div>
                <div class="distance-to-reel">({{ rod.distanceToReel.toFixed(2) }} м)</div>
              </div>
              <span v-if="rod.bait !== null">({{ rod.bait.name }})</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Джойстик -->
      <Joystick></Joystick>
    </div>

    <!-- Диалоговое окно -->
    <FullScreenDialog
        :isVisible="isDialogVisible"
        :title="dialogTitle"
        :fish-name="dialogFishName"
        :message="dialogMessage"
        @close="closeDialog"
    />
  </div>
</template>


<script>
// Для хранилища
import { mapState, mapActions } from 'vuex'

// Ядро
import Core from '@/scenes/shore/classes/Core'

// Джойстик
import Joystick from '@/scenes/shore/Joystick'

// Диалоговое окно во весь экран
import FullScreenDialog from '@/scenes/shore/FullScreenDialog'

// Обработчики событий ввода
const { handlersMixin } = require('@/scenes/shore/mixins/handlers.js')

export default {
  name: 'Shore',
  components: { Joystick, FullScreenDialog },
 // Рыбалка с берега
  setup() {
    let log = Core.log // Создаем реактивное свойство для лога
    let caughtMessage = Core.caughtMessage // Сообщение о пойманой рыбе
    return { log, caughtMessage }
  },
  data() {
    return {
    }
  },
  mounted() {
    // Устанавливаем canvas в Core
    Core.canvas = this.$refs.gameCanvas

    // Инициализируем store
    this.$store.commit('INIT_STORE');

    // Рисуем игру
    this.$nextTick(() => {
      Core.draw()

      // Добавляем слушатель нажатий клавиш для всего компонента
      window.addEventListener('keydown', this.handleKeyDown)
      window.addEventListener('keyup', this.handleKeyUp)

      // // Инициализация Telegram WebApp
      // const tg = window.Telegram.WebApp;
      // tg.ready(); // Готовность приложения
      // tg.expand(); // Развернуть приложение на весь экран
      // tg.enableClosingConfirmation();

      // // Развернуть на весь экран
      // window.Telegram.WebApp.expand();
      //
      // // Запретить случайное закрытие
      // window.Telegram.WebApp.enableClosingConfirmation();
    })
  },
  beforeUnmount() {
    // Удаляем слушателей нажатий клавиш с компонента
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  mixins: [
      handlersMixin
  ],
  computed: {
    // Получаем свойства для компонента из хранилища
    ...mapState([
        'user',
        'baits', 'rods', 'selectedRod', 'selectedBait', 'isReeling', 'isDialogVisible', 'dialogTitle',
        'dialogFishName', 'dialogMessage',
    ]),
  },
  methods: {
    // Получаем методы для компонента из хранилища
    ...mapActions([
        'setSelectedRod', 'setSelectedBait',
        'closeDialog',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Занимает всю высоту экрана */
}

.top-block {
  height: 100px; /* Фиксированная высота верхнего блока */
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc; /* Граница для визуального разделения */
}

.bottom-block {
  height: 200px; /* Фиксированная высота нижнего блока */
  border-top: 1px solid #ccc; /* Граница для визуального разделения */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.center-block {
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .center-block {
    justify-content: flex-start; /* На мобильных устройствах выравниваем по левому краю */
  }
}

.user-info {
  color: #fff;
}

canvas {
  display: block;
  height: 100%; /* Занимает всю высоту center-block */
  width: auto; /* Ширина будет автоматически подстраиваться под пропорции */
  border: none;
  margin: 0;
}

.interface {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .baits {
    display: flex;
    flex-direction: row;
    .bait {
      margin-right: 10px;
      padding: 5px 10px;
      border: 1px solid #000;
      cursor: pointer;
      &.selected {
        background-color: #808000;
      }
    }
  }
  .rods {
    display: flex;
    flex-direction: row;
    .rod-container {
      display: flex;
      margin-bottom: 20px; /* Отступ между удочками */
      .rod {
        margin-right: 5px;
        padding: 2px;
        cursor: pointer;
        border: #fff 2px solid;
        &.selected {
          background-color: #808000;
          border: #606002 2px solid;
        }
        &.is-biting {
          border: #45b947 2px solid;
        }
        .rod-info {
          display: flex;
          .rod-name {
            font-size: 12px;
          }
          .distance-to-reel {
            font-size: 12px;
            text-align: center; /* Центрируем текст */
            margin-bottom: 5px; /* Отступ между текстом и кнопкой удочки */
          }
        }
        span {
          display: block;
          font-size: 10px;
        }
        .tension-bar {
          width: 100%; /* Полная ширина контейнера */
          height: 5px; /* Высота полосы */
          background-color: #ddd; /* Цвет фона полосы */
          border-radius: 5px; /* Закругление углов */
          overflow: hidden; /* Чтобы содержимое не выходило за пределы */
          .tension-fill {
            height: 100%; /* Полная высота полосы */
            background-color: #f00; /* Цвет заполнения полосы (красный) */
            transition: width 0.2s ease; /* Плавный переход при изменении ширины */
          }
        }
      }
    }
  }
}
.message {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: green;
  margin-top: 10px;
}
.log {
  text-align: center;
  font-size: 10px;
  color: #fff;
  margin-top: 5px;
}
</style>