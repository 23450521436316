<template>
  <!-- Джойстик -->
  <div class="joystick-container" ref="joystickContainer">
    <div class="joystick-background"></div>
    <div
        class="joystick-thumb"
        :style="joystickThumbStyle"
        @mousedown="startJoystick"
        @touchstart="startJoystick"
    ></div>
  </div>
</template>

<script>
import Core from '@/scenes/shore/classes/Core'
import { mapState } from 'vuex'

export default {
  name: 'Joystick',
  data() {
    return {
      joystickActive: false,
      joystickPosition: { x: 0, y: 0 },
      moveSpeed: 0, // Скорость движения удочки
      reelSpeed: 0, // Скорость сматывания лески
      y: 0, // Позиция джойстика по Y
    };
  },
  computed: {
    // Получаем свойства для компонента из хранилища
    ...mapState(['baits', 'rods', 'selectedRod', 'selectedBait', 'isReeling']),
    joystickThumbStyle() {
      return {
        transform: `translate(calc(${this.joystickPosition.x}px - 50%), calc(${this.joystickPosition.y}px - 50%))`,
      };
    },
  },
  methods: {
    // Запуск джойстика
    startJoystick(event) {
      if (!this.joystickActive && this.selectedRod !== null && this.selectedRod.isBiting === true) {
        this.joystickActive = true;
        this.selectedRod.inHands = true // Взяли удочку в руки
        window.addEventListener("mousemove", this.handleJoystickMove);
        window.addEventListener("mouseup", this.stopJoystick);
        window.addEventListener("touchmove", this.handleJoystickMove, { passive: false });
        window.addEventListener("touchend", this.stopJoystick);
        this.handleJoystickMove(event);
        this.startRodAnimation(); // Запускаем анимацию
      }
    },

    // Обработчик движения джойстика
    handleJoystickMove(event) {
      if (!this.joystickActive) return;

      const rect = this.$refs.joystickContainer.getBoundingClientRect();
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;

      let clientX, clientY;

      if (event.touches) {
        // Для сенсорных устройств
        clientX = event.touches[0].clientX - rect.left;
        clientY = event.touches[0].clientY - rect.top;
      } else {
        // Для мыши
        clientX = event.clientX - rect.left;
        clientY = event.clientY - rect.top;
      }

      // Ограничиваем движение кружка в пределах прямоугольника
      const maxX = rect.width / 2 - 20; // 20 - радиус кружка
      const maxY = rect.height / 2 - 20;

      this.joystickPosition.x = Math.max(-maxX, Math.min(maxX, clientX - centerX));
      this.joystickPosition.y = Math.max(-maxY, Math.min(maxY, clientY - centerY));

      // Управление джойстиком
      this.handleJoystickInput();
    },

    // Отпустили джойстик
    stopJoystick() {
      this.joystickActive = false;
      this.joystickPosition = { x: 0, y: 0 };
      this.moveSpeed = 0; // Сбрасываем скорость движения
      this.reelSpeed = 0; // Сбрасываем скорость сматывания
      window.removeEventListener("mousemove", this.handleJoystickMove);
      window.removeEventListener("mouseup", this.stopJoystick);
      window.removeEventListener("touchmove", this.handleJoystickMove);
      window.removeEventListener("touchend", this.stopJoystick);

      if (this.selectedRod) {
        this.selectedRod.isReeling = false;
        this.selectedRod.stopReeling();
      }
    },

    // Управление джойстиком
    handleJoystickInput() {
      if (!this.selectedRod) return;

      const { x, y } = this.joystickPosition;
      this.y = y
      const rect = this.$refs.joystickContainer.getBoundingClientRect();
      const maxX = rect.width / 2 - 20;
      //const maxY = rect.height / 2 - 20;

      // Управление наматыванием лески
      //Core.setLog(y)
      // if (y > 0) {
      //   this.reelSpeed = (y / maxY) * 0.02; // Сохраняем скорость сматывания
      //   this.selectedRod.isReeling = true;
      // } else {
      //   this.reelSpeed = 0; // Сбрасываем скорость сматывания
      //   this.selectedRod.isReeling = false;
      // }

      // Управление движением удочки
      this.moveSpeed = x / maxX; // Сохраняем скорость движения
    },

    // Анимация удочки и наматывания лески
    startRodAnimation() {
      const animate = () => {
        if (this.joystickActive && this.selectedRod && this.selectedRod.isBiting === true) {
          // Двигаем удочку
          this.selectedRod.move(this.moveSpeed, () => this.selectedRod?.isReeling || false);

          // Сматываем леску, если isReeling true
          //if (this.selectedRod.isReeling) {
          // if (this.selectedRod !== null && this.selectedRod.isReeling === false) {
          //   // this.selectedRod.inHands = true // Взяли удочку в руки
          //   // this.selectedRod.isReeling = true // Начинаем наматывать леску
          //   this.selectedRod.reelIn(Core.canvas.height, () => this.selectedRod?.isReeling || false)
          // }

          if (this.y > 0) {
            if (this.selectedRod !== null && this.selectedRod.isReeling === false) {
              // this.selectedRod.inHands = true // Взяли удочку в руки
              this.selectedRod.isReeling = true // Начинаем наматывать леску
              this.selectedRod.reelIn(Core.canvas.height, () => this.selectedRod?.isReeling || false)
            }
          } else {
            this.selectedRod.isReeling = false;
          }

          requestAnimationFrame(animate);
        }
      };
      // Core.draw()
      animate();
    },
  }
}
</script>

<style lang="scss" scoped>
.joystick-container {
  flex-shrink: 0; /* Запрещает сжатие */
  flex-grow: 0;   /* Запрещает растяжение */
  position: relative;
  width: 120px; /* Ширина прямоугольника */
  height: 120px; /* Высота прямоугольника */
  border: #555 1px solid;
  border-radius: 20px;
  overflow: hidden;
}

.joystick-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.joystick-thumb {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #808000;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Центрируем кружок */
  cursor: pointer;
  touch-action: none; /* Отключаем стандартное поведение касания */
}
</style>